/*-----------------------------------------------------------------------------------

    Theme Name: Frannerd
    Author: membranaestudio
    Author URI: http://www.membranaestudio.cl/
    Version: 5.8

    SASS Stylesheet

-----------------------------------------------------------------------------------*/


// Initialize
@import "sass/variables.scss";
@import "sass/mixins.scss";


// Core CSS
@import "sass/typography.scss";
@import "sass/helpers.scss";
@import "sass/layouts.scss";


// Content Blocks
//@import "sass/topbar.scss";
@import "sass/header.scss";
//@import "sass/sliders.scss";
//@import "sass/pagetitle.scss";
@import "sass/content.scss";
@import "sass/portfolio.scss";
//@import "sass/blog.scss";
//@import "sass/shop.scss";
//@import "sass/events.scss";


// Shortcodes
@import "sass/shortcodes.scss";


// Footer
//@import "sass/footer.scss";


// Widgets
//@import "sass/widgets.scss";


// Extras
@import "sass/extras.scss";


// Frannerd
@import "sass/fran/globales.scss";

