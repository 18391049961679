
/*-----------------------------------------------------------------------------------

	Shortcodes: misc.scss

-----------------------------------------------------------------------------------*/



/* ----------------------------------------------------------------
	Go To Top
-----------------------------------------------------------------*/


#gotoTop {
	display: none;
	z-index: 299;
	position: fixed;
	width: 40px;
	height: 40px;
	background-color: transparent;
	font-size: 20px;
	line-height: 36px;
	text-align: center;
	color: #FFF;
	top: auto;
	left: auto;
	right: 30px;
	bottom: 40px;
	cursor: pointer;
}

body {
	&:not(.device-touch) {
		#gotoTop {
			@include transition(background-color .2s linear);
		}
	}
}

.stretched {
	#gotoTop {
		bottom: 30px;
	}
}




/* ----------------------------------------------------------------
	Preloaders
-----------------------------------------------------------------*/

.preloader,
.preloader2,
.form-process {
	display: block;
	width: 100%;
	height: 100%;
	background: url("images/preloader.gif") center center no-repeat #FFF;
}
.preloader2 {
	background-color: transparent;
}
.form-process {
	display: none;
	position: absolute;
	z-index: 3;
	background-color: rgba(255,255,255,0.7);
}
