/* CONTAINER */

@media(min-width:1360px){
   .container {
        max-width: 1200px;
    }
}

$morado: #4f58ee;




/* MENU */

#header2 {
    margin-top: 60px;
    #logo2 { padding-right: 40px;}
}

#menu {
    ul.superior {
        position: relative; 
        margin-top: 40px;
        li {
            display: inline-block;
            padding-right: 24px;
        }
        li.work {
            width: 104px;
        }
        li.about {
            width: 173px;
        }
        li.book {
            width: 200.5px;
        }

    }
    ul.inferior {
        li {
            display: inline-block;
            padding-right: 24px;
        }
        li.contact {
            width: 128.5px;
        }
        li.shopp {
            width: 94px;
        }
        li.redes {
            width: 46px;
            padding-right: 6px;
            position: relative;
            left: 10px;
        }
    }
}






h2 { font-size: 21px; font-family: 'VistaSansReg'; font-weight: 500}

/* Contacto */

.page-template-contact {
    .content-wrap { padding-top: 160px !important}
    .m1 { padding-right: 100px;}
    .m2 {
        margin-top: 40px;
    }
    .contenedor {
        margin-bottom: 22px;
    }
    p,
    a {
        margin: 0px;
        font-family: 'VistaSansAltReg';
        font-weight: 500;
        letter-spacing: 0.4px;
        line-height: 22px !important;
    }

/* Works */

}

.single-work, .single-books_zines {
    .content-wrap {
        padding-top: 10px !important
    }
    #content img {

        margin-bottom: 30px;
    }
    .opcion2 p { margin-top: 50px;} 
}





/* Contacto */

.page-template-about {
    h2 {
        margin-top: 20px;
    }
    #content img {
        margin-bottom: 12px;
    }
    .conmargen {
        padding: 0px 26px;
    }
}

.cerrar {
    float: right;
    margin-bottom: 20px;
    img {
        width: 22px;
    }



}





@media(max-width:1199px) {
    #header2 #logo2 {
        padding-right: 30px;
    }
    #menu {
        ul.superior {
            position: relative;
            margin-top: 20px;
            li {
                display: inline-block;
                padding-right: 20px;
            }
            li.work {
                width: 90px;
            }
            li.about {
                width: 159px;
            }
            li.book {
                width: 186.5px;
            }

        }
        ul.inferior {
            li {
                display: inline-block;
                padding-right: 20px;
            }
            li.contact {
                width: 114.5px;
            }
            li.shopp {
                width: 80px;
            }
            li.redes {
                width: 42px;
                padding-right: 6px;
                position: relative;
                left: 10px;
            }
        }
    }
    .cerrar img {
        width: 22px;
    }
    .portfolio-desc h3 {font-size: 17px}
}

@media(min-width:992px) {
    #header {
        display: none
    }

    #portfolio {
        #margen-1 {
            padding-left: 60px;
        }
        #margen-3 {
            padding-left: 10px;
            padding-right: 50px;
        }
        #margen-5 {
            padding-left: 10px;
        }

    }
    .titulo-movil { display: none;}
}

@media(max-width:991px) {
    #header2 {
        display: none
    }
    #logo img {
        width: 380px;
        height: auto;
        top: 46px;
        position: relative;

    }
    #primary-menu-trigger {
        top: 56px !important;
        img {
            width: 26px
        }

    }
    .single-work #content img, .single-books_zines #content img { margin-bottom: 20px;}
    .single-work .opcion2 p, .single-books_zines .opcion2 p { margin-top: 0px}
    
    
    
    
    #primary-menu li a img {
        height: 28px;
    }
    body #primary-menu ul {
        margin-top: 40px !important
    }
    body #primary-menu ul.cont-redes {
        img {
            margin-left: 8px;
        }
        margin-top: 20px !important;
        text-align: center;
        li.redes {
            display: inline-block !important
        }

    }
    .cerrar img {
        width: 20px;
    }
    h2 {
        font-size: 20px
    }
    .single-work #content .cerrar img, .single-books_zines #content .cerrar img {
        margin-top: 10px;
        margin-bottom: 0px;

    }
    .titulo-escritorio { display: none}
    .titulo-movil { margin-bottom: 14px}

}

@media(max-width:767px) {
    #logo img {
        width: 320px;

    }
    #primary-menu-trigger {
        top: 50px !important;
        img {
            width: 24px
        }

    }
    #primary-menu li a img {
        height: 26px;
    }
    h2 { font-size: 20px !important} 
    body { font-size: 13px}
    .page-template-contact .content-wrap { padding-top: 90px !important;}
    .page-template-contact .m1 {padding-right: 15px;
}
}

@media(max-width:575px) {
    #logo img {
        width: 280px;

    }
    #primary-menu-trigger {
        top: 44px !important;
        img {
            width: 20px
        }

    }
    #primary-menu li a img {
        height: 26px;
    }
    body #primary-menu ul {
        margin-top: 20px !important;
    }
    body #primary-menu ul > li > a,
    #primary-menu.style-2 > div > ul > li > a {
        padding: 8px 0px !important
    }

    #content .content-wrap {
        padding: 60px 0;
    }
    .cerrar img {
        width: 16px;
    }
    .cerrar { margin-bottom: 10px}
    h2 {
    font-size: 17px !important;
    line-height: 24px;

}
    
    
    
    
    
}

@media(max-width:420px) {
    #logo img {
        width: 240px;

    }
    #primary-menu-trigger {
        top: 40px !important;
        img {
            width: 20px;
        }

    }
}

@media(max-width:350px) {
    #primary-menu-trigger {
        left: 0px !important;

    }
    #logo img {
        width: 220px;

    }
    
    
    
}















